import React from "react";
import moment from "moment";

import { Button, Card, List, Select, Badge, message } from "antd";
import { StarFilled, MessageFilled } from "@ant-design/icons";

import { LogTimeModal, TimeLoggedModal, TypeModal } from "./Modals";
import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";
import IncidentDrawer from "./IncidentDrawer";

const timeLogFormat = "HH:mm";

export default function RightInformationCard({
  user,
  ticket,
  setTicket,
  isArchived,
  isModalOpen,
  setIsModalOpen,
  isTypeModalOpen,
  setIsTypeModalOpen,
  handleSend,
}) {
  const [types, setTypes] = React.useState([]);
  const [priorities, setPriorities] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [agents, setAgents] = React.useState([]);
  const [timeLog, setTimeLog] = React.useState([]);
  const [resolved, setResolved] = React.useState(false);
  const [messageCount, setMessageCount] = React.useState(0);
  const [totalTimeLog, setTotalTimeLog] = React.useState(moment.duration());
  const [isModalLogsOpen, setIsModalLogsOpen] = React.useState(false);
  const [time, setTime] = React.useState(moment("00:00", timeLogFormat));
  const [updateTimeLogs, setUpdateTimeLogs] = React.useState(true);
  const [incidentOpen, setIncidentOpen] = React.useState(false);
  const [prioritiesMap, setPrioritiesMap] = React.useState({});

  React.useEffect(() => {
    client("tickets/status", { token: user.access_token }).then(
      (responseData) => {
        const status = responseData
          .filter((value) => value !== "Done")
          .map((value) => {
            return { label: value, value };
          });
        setStatus(status);
      }
    );
    client("tickets/types", { token: user.access_token }).then(
      (responseData) => {
        const types = responseData.map((value) => {
          return { label: value, value };
        });
        setTypes(types);
      }
    );
    client("tickets/priorities", { token: user.access_token }).then(
      (responseData) => {
        const priorities = [];
        for (var key in responseData) {
          priorities.push({ label: responseData[key], value: key });
        }
        setPriorities(priorities);
        setPrioritiesMap(responseData);
      }
    );
    client("users/agents", { token: user.access_token }).then((response) => {
      const agents = response.map((user) => {
        return { id: user.id, label: user.username, value: user.username };
      });
      setAgents(agents);
    });
  }, [user.access_token]);

  React.useEffect(() => {
    if (!updateTimeLogs) {
      return;
    }
    client(`time-log/ticket/${ticket.id}`, {
      token: user.access_token,
    })
      .then((responseData) => {
        if (!responseData || !responseData.length) {
          setUpdateTimeLogs(false);
          return;
        }
        const dataSource = responseData.map(
          ({ id, timeWorked, user, createdAt }) => {
            const duration = moment.duration(timeWorked, "seconds");
            return {
              key: id,
              timeWorked: `${parseInt(duration.asHours())} uur ${duration.get(
                "minutes"
              )} minuten`,
              username: user.username,
              createdAt: formatDateTime(createdAt),
            };
          }
        );
        setTimeLog(dataSource);
        const totalTime = responseData.slice(1).reduce((prev, cur) => {
          return prev.add(cur.timeWorked, "seconds");
        }, moment.duration(responseData[0].timeWorked, "seconds"));

        setTotalTimeLog(totalTime);
        setUpdateTimeLogs(false);
      })
      .catch((response) => {
        if (response.status === 404) {
          return;
        }
        message.error("Er is iets misgelopen met het updaten van de data.");
      });
  }, [user.access_token, updateTimeLogs, ticket.id]);

  if (!ticket) {
    return;
  }

  const updateTicketField = (field, value) => {
    const data = { [field]: value };
    client(`tickets/${ticket.id}`, {
      token: user.access_token,
      data,
      method: "PUT",
    }).then((response) => setTicket(response));
  };

  const handleOk = () => {
    const duration = moment.duration(time.format(timeLogFormat));
    const data = {
      timeWorked: duration.asSeconds(),
      ticketId: ticket.id,
    };
    client("time-log", { token: user.access_token, data }).then(() => {
      setUpdateTimeLogs(true);
      setIsModalOpen(false);
      setTime(moment("00:00", timeLogFormat));
    });
  };

  return (
    <Card title="Ticket informatie">
      <List>
        <List.Item>
          <b>Aangemaak door:</b>
          <br /> {ticket.username}
        </List.Item>
        <List.Item style={{ display: "block" }}>
          <b>Toegewezen agent:</b>
          <Select
            showSearch
            disabled={isArchived}
            defaultValue={ticket.agent ? ticket.agent.username : null}
            style={{ width: 200 }}
            placeholder="Kies een agent"
            optionFilterProp="label"
            onChange={(value, option) =>
              updateTicketField("agent_id", option.id)
            }
            options={agents}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </List.Item>
        <List.Item style={{ display: "block" }}>
          <b>Tijd log:</b>
          <p>
            {parseInt(totalTimeLog.asHours())} uur {totalTimeLog.get("minutes")}{" "}
            minuten
          </p>
          <Button
            disabled={isArchived}
            type="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Log tijd
          </Button>{" "}
          <Button onClick={() => setIsModalLogsOpen(true)}>
            Bekijk logs
          </Button>
          <LogTimeModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            timeLogFormat={timeLogFormat}
            time={time}
            setTime={setTime}
            handleOk={handleOk}
          />
          <TimeLoggedModal
            isModalOpen={isModalLogsOpen}
            setIsModalOpen={setIsModalLogsOpen}
            timeLogFormat={timeLogFormat}
            dataSource={timeLog}
          />
        </List.Item>
        <List.Item style={{ display: "block" }}>
          <b>Status:</b>
          <Select
            disabled={isArchived}
            value={ticket.status}
            style={{ width: 200 }}
            placeholder="Kies een status"
            onChange={(value) => updateTicketField("status", value)}
            options={status}
          />
        </List.Item>
        <List.Item>
          <Button type="primary" onClick={() => setIncidentOpen(true)}>
            Ivanti Incident
          </Button>
          {ticket.hasIvantiResponse ? (
            <MessageFilled style={{ color: "#fa541c" }} />
          ) : null}
          {resolved ? <StarFilled style={{ color: "#fadb14" }} /> : null}
          <Badge count={messageCount} style={{ backgroundColor: "#52c41a" }} />
          <IncidentDrawer
            user={user}
            ticketId={ticket.id}
            open={incidentOpen}
            setOpen={setIncidentOpen}
            setResolved={setResolved}
            setMessageCount={setMessageCount}
            isArchived={isArchived}
            handleSend={handleSend}
          />
        </List.Item>
        <List.Item style={{ display: "block" }}>
          <b>Type:</b>
          <Select
            disabled={isArchived && ticket.type ? true : false}
            value={ticket.type}
            style={{ width: 200 }}
            placeholder="Kies een type"
            onChange={(value) => updateTicketField("type", value)}
            options={types}
          />
        </List.Item>
        <TypeModal
          isModalOpen={isTypeModalOpen}
          setIsModalOpen={setIsTypeModalOpen}
          options={types}
          handleChange={({ target: { value } }) => {
            updateTicketField("type", value);
            setIsTypeModalOpen(false);
          }}
        ></TypeModal>
        <List.Item style={{ display: "block" }}>
          <b>Prioriteit:</b>
          <Select
            disabled={isArchived}
            value={prioritiesMap[ticket.priority]}
            style={{ width: 200 }}
            placeholder="Kies een prioriteit"
            onChange={(value) => updateTicketField("priority", value)}
            options={priorities}
          />
        </List.Item>
        <List.Item>
          <b>Aangemaakt op:</b>
          <br /> {formatDateTime(ticket.createdAt)}
        </List.Item>
        <List.Item>
          <b>Laatst geupdate:</b>
          <br /> {formatDateTime(ticket.updatedAt)}
        </List.Item>
      </List>
    </Card>
  );
}
